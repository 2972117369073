import axios from "axios";

const URL = "https://hyundaicloud.umbraint.com/api/"

export const HomeBanner =  async () => {
    return await axios.get(URL + "hero-banners?sort=order:asc&populate[media]=*&populate[thumbnail]=*&populate[title][populate]=text_color&populate[security_seal][populate]=image&populate[info]=*");
}

export const HomePortafolio =  async () => {
    return await axios.get(URL + "portfolios?sort=order:asc&populate[vehicle][fields]=name,price,price_special,price_special_deadline&populate[vehicle][populate]=security_seal&populate[image]=*");
}

export const HomeCallToAction =  async () => {
    return await axios.get(URL + "opportunities?sort=order:asc&populate=*");
}

export const HomeSuscription =  async (data: any) => {
    const url = 'https://hyundaicloud.umbraint.com/api/form-subscriptions';
  const token = 'e4d1b3b5ed8c917d8e687f9e6f6734b489f70c4ce1bad187a5478253ccc07013071406dbf60fe87aa10b8e4f2c4f47fc54342c1e1da71fa63ad15d3ab97098ffc360cfc4ce170d65fe120a325a907f2ef752ebf2a6692f3611ebc26549ea4bba1993638109d9412f12908780f8a1d8e7b973b717028b07c67114e2360a46984d';

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

    return await axios.post(url, data, { headers });
}