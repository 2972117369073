import React, { Suspense, lazy } from 'react';
import "./styles/style.css"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ScrollToTop from './layouts/scroll.layout';
import PoliticasTerminosCondicionesPage from './pages/politicas_terminos_condiciones.page';
import PoliticasTerminosReservasPage from './pages/politicas_terminos_reservas.page';
import PoliticasPaginaPage from './pages/politicas_pagina.page';
import PoliticasAvisoPrivacidadPage from './pages/politicas_aviso_privacidad.page';
import PoliticasProteccionDatosPage from './pages/politicas_proteccion_datos.page';
import PoliticasCalidadPage from './pages/politicas_calidad.page';
import PoliticasCookiesPage from './pages/politicas_cookies.page';

const HomePage = lazy(() => import('./pages/home.page'));
const VehiculosPage = lazy(() => import('./pages/vehiculos.page'));
const VehiculosDetailPage = lazy(() => import('./pages/vehiculos-detail.page'));
const SimuladorPage = lazy(() => import('./pages/simulador.page'));

const NotFound: React.FC = () => {
  return (
    <div>
      <header className="App-header">
        <h1>404 - Página no encontrada</h1>
        <p>Lo sentimos, la página que buscas no existe.</p>
        <Link to="/" className="App-link">
          Volver al inicio
        </Link>
      </header>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* Suspense envuelve los componentes con lazy loading */}
        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/vehiculos" element={<VehiculosPage />} />
            <Route path="/vehiculos/:vehiculo" element={<VehiculosDetailPage />} />
            {/* <Route path="/quienes-somos" element={<QuienesSomos />} /> */}
            <Route path="/simulador-de-cuotas/:vehiculo?" element={<SimuladorPage />} />
            {/* <Route path="/contacto" element={<Contacto />} /> */}
            <Route path="/terminos-condiciones" element={<PoliticasTerminosCondicionesPage />} />
            <Route path="/terminos-reservas-online" element={<PoliticasTerminosReservasPage />} />
            <Route path="/politica-pagina" element={<PoliticasPaginaPage />} />
            <Route path="/avisos-privacidad" element={<PoliticasAvisoPrivacidadPage />} />
            <Route path="/politica-proteccion-datos" element={<PoliticasProteccionDatosPage />} />
            <Route path="/politica-calidad" element={<PoliticasCalidadPage />} />
            <Route path="/politica-cookies" element={<PoliticasCookiesPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
